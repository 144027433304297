/* You can add global styles to this file, and also import other style files */
/* Override user agent style sheet */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
body{
    font-family: 'Open Sans', sans-serif !important;
    /*font-family: 'Roboto', sans-serif !important;*/
}

h1,h2,h3,h4,h5,h6{
    /*font-family: 'Open Sans', sans-serif !important;*/
    font-family: 'Roboto', sans-serif !important;
}
html{
    background: #fafafa;
}
a {
    text-decoration: none;
}

/********************************************

Modal styles

********************************************/

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 990;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.modal .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0,0,0,0.85);
}
.modal .modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #cac9c9;
    padding-bottom: 12px;
}
.modal .modal-header h3{
    margin: 0;
}

.modal .modal_content {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*min-height: 60%;*/
    max-height: 80%;
    overflow: auto;
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.7);
    border-radius: 4px;
    width: 80vw; /* This just a default width */
}

.modal .modal_content > h2 {
    font-size: 28px;
    font-weight: 200;
    margin: 20px 0 40px;
    text-align: center;
}

.modal .modal_content .buttons_wrapper {
    padding: 20px;
}

.modal .close_modal {
    cursor: pointer;
    font-size: 18px;
    opacity: 0.5;
    border: none;
    transition: opacity 0.2s ease;
}

.modal .close_modal:hover {
    opacity: 0.9;
}

/*******************************************/



input:focus{
    outline: none;
}

button:focus{
    outline: none;
}

.modal-header{
    padding: 0;
}
.table-responsive{
    overflow-x: auto;
}

.center-align {
    text-align: center;
  }

.container-t{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width:100%;
}
.p-0{
    padding: 0;
}
.custom-card{
    border: 1px solid #cac9c9;
    border-radius: 10px;
    background: white;
    margin:20px 0;
    box-shadow: 0 0 15px 0px #9c9b9b;
}
.custom-card .card-header {
    border-bottom: 1px solid #cac9c9;
    padding: 20px;
}
.custom-card .card-header h3{
    margin: 0;
}
.custom-card .card-body {
    padding: 20px;
}
.card-red .card-header {
    background: darkred; border-radius: 10px 10px 0 0;
}
.card-red .card-body {
    background: #f9bbb6; border-radius: 0px 0px 10px 10px;
}

@media (min-width: 1200px){
    .container-t{
        max-width: 1140px;
    }
}

.login-form{
    display: flex;
    height: 100vh;
    align-items: center;
    background-image: url(./assets/login-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}
.login-form .form-wrapper{
    background: white;
    max-width: 400px;
    padding: 40px;
    margin: 0 auto;
}

.login-form .form-wrapper h2{
    margin-top: 0;
    text-align: center;
}
.login-form .form-wrapper h5{
    margin: 5px auto 10px;
    text-align: center;
}
.login-form label{
    width: 100%;
}
.login-form input#username{
    border: none;
    border-bottom: 1px solid grey;
    width: 100%;
}
.login-form button{
    width: 100%;
    border-radius:0;
    margin-top: 20px;
}

.tiles{
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    /*max-width: 285px;*/
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
.tiles .card {
    display: flex;
    border: none;
    background-color: #0077b8 ;
    margin-top: 1rem;
    box-shadow: 0 0.125rem 0 0 transparent;
    border-radius: 9px;
    align-items: center;
}
.tiles .card:hover {
    border:none;
    transform: translateY(0px);
    box-shadow: 0 0.125rem 0 0 transparent;
}
.tiles .card-img{
    padding: 15px;
    background: white;
    border-radius: 9px 70px 70px 9px;
    border: 1px solid  #0077b8
}
.tiles .card-img img{
   width: 50px;
    max-width: 50px;
}

.tiles .card-text{
    color: white;
    font-size: 20px;
}
.tiles:hover .card-img img{
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}
.tiles:hover .card{
    background-color: white ;
    /*border: 1px solid #0077b8;*/
}
.tiles:hover .card-text{
    color: #0077b8;
}

.tiles:hover .card-img{
    background: #0077b8;
    /*border: 1px solid  #0077b8*/
}
clr-icon[shape="minus-circle"]{
    vertical-align: text-bottom !important;
}
.search-bar{
    border: 1px solid grey;
    padding: 0 10px;
    width: 100%;
    border-radius: 8px;
}
.tiles-wrapper{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left:-15px ;
    margin-right:-15px ;
}
/*forms*/
.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.form-group label {
    flex: none;
    display: block;
    width: 150px !important;
    font-weight: bold;
    font-size: 1em;
    margin: 0 !important;
}

.form-group .input-control {
    flex: 1 1 auto;
    /*display: block;*/
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    /*-o-appearance: none;*/
    /*margin: 0 0 10px;*/
    /*border: 0;*/
    /*border-radius: 0;*/
    /*box-shadow: none;*/
    /*background: 0 0;*/
    /*height: 1rem;*/
    /*color: #000;*/
    /*border-bottom: 1px solid #999;*/
    /*padding: 0 .25rem;*/
    /*max-height: 1rem;*/
    /*font-size: .541667rem;*/

    display: block;
    width: 100%;
    padding: .2rem .5rem;
    /* font-size: 1rem; */
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.form-group .input-control:focus {
    border: 1px solid #0092d1;
    background-size: 100% 100%;
}

.form-group label.right-inline {
    /*text-align: right;*/
    padding-right: 8px;
    padding-left: 20px;
    width: auto;
}

.form-group select{
    /*margin-bottom: 10px;*/
}

.phone-button {
    padding-left: 1rem;
    padding-right: 1rem;
}
.phone-button img{
    width: 20px;
    cursor:pointer;
}

.login-form .form-group{
    display: block;
}
.login-form .form-group label{
    width: 100% !important;
}

/* style for the css menu dropdown container */
.menu {
    justify-content: center;
    position:relative;
    display:inline-block;
    z-index:2;
    padding:0;
    margin:0;
    outline:0;
    text-align:left;
    align-self: center;

}

.nav-item{
    align-self: center;
}
.menu#ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.dropdown-c{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
}
.dropdown-c .name{
    font-size: 22px;
    margin: 0 10px;
}

/* style for the button */
/*.menu:before {*/
/*    content: "\2630 \a0 Menu";*/
/*    color:white;*/
/*    padding: 5px 10px;*/
/*    border: 1px solid #555;*/
/*    border-radius:3px;*/
/*    background: #96305e;*/
/*    background: -moz-linear-gradient(top,  #96305e 0%, #822956 10%, #822956 90%, #751749 100%);*/
/*    background: -webkit-linear-gradient(top,  #96305e 0%,#822956 10%,#822956 90%,#751749 100%);*/
/*    background: linear-gradient(to bottom,  #96305e 0%,#822956 10%,#822956 90%,#751749 100%);*/
/*    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#96305e', endColorstr='#751749',GradientType=0 );*/
/*}*/

.menu:focus { pointer-events:none; }

/* If :focus detected the dropdown menu is displayed by making it visible */
.menu:focus .menu-dropdown { opacity:1; visibility:visible; }

/* style for the dropdown box */
.menu-dropdown {
    background-color: white;
    width: auto;
    margin: 3px 0 0 0;
    border-radius: 3px;
    border: 1px solid #cac9c9;
    border-radius: 3px;
    pointer-events: auto;
    position: absolute;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.5s;
}

/* style the links in the dropdown */
.menu-dropdown a {
    background:transparent;
    border-bottom: 1px solid #cac9c9;
    outline:0;
    display:block;
    color:black;
    text-decoration:none;
    width:160px;
    padding:6px 10px;
    cursor: pointer;
}
.menu-dropdown a:last-child{
    border: none;
}


.menu-dropdown a:hover {
    background:#d0e2f1
}

/* calendar styles*/
.cal-day-selected,
.cal-day-selected:hover {
    background-color: #addffb  !important;
    padding-bottom: 10px;
}
.cal-day-selected-current,
.cal-day-selected-current:hover {
    background-color: pink  !important;
    padding-bottom: 10px;
}
/* end calendar styles*/

.calender-badge{
    cursor: pointer;
    background: #0077b8;
    padding: 0 10px;
    color: white;
    border-bottom: 1px solid white;
}
.cal-month-view .cal-day-number {
    color: #000000 !important;
    opacity: 0.9;
}
.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.5 !important;
}
.calender-wrapper{
    display: block;
    overflow-x: auto;
    width: 100%;
}


@media (max-width: 768px) {
    .form-group {
        flex-direction: column;
    }
    .form-group .input-control {
        margin-top: 2px;
    }
    .form-group label{
        width: 100% !important;
    }
    .form-group label.right-inline {
        text-align: left;
        padding-right: 0;
        padding-left: 0;
        margin-top: 20px !important;
    }
}

/*MEDIA QUERIES*/
@media (min-width: 1200px) {

}

@media (min-width: 991px) {

}

@media (min-width: 768px) {

}

@media (min-width: 576px) {

}

.navigation{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    list-style: none;
    margin: 0;
    background: #0077b8;
    border-radius: 9px;
}

.navigation a {
    text-decoration: none;
    display: block;
    padding: 1em;
    color: white;
}
.navigation div {
    text-decoration: none;
    display: block;

    /*color: white;*/
}
.navigation a:hover {
    background: #1565C0;
}
@media all and (max-width: 800px) {
    .navigation {
        justify-content: space-around;
    }
}

@media all and (max-width: 600px) {
    .navigation {
        flex-flow: column wrap;
        padding: 0;
    }
    .navigation a{
        text-align: center;
        padding: 10px;
        border-top: 1px solid rgba(255, 255, 255,0.3);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .navigation li:last-of-type a div {
        border-bottom: none;
    }
}



.btn {
    cursor: pointer;
    display: inline-block;
    -webkit-appearance: none !important;
    border-radius: 0.125rem;
    min-width: 3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    border: 1px solid #0079b8;
    background-color: transparent;
    color: #0079b8;
    line-height: 1.5rem;
    letter-spacing: 0.12em;
    /*font-size: 0.5rem;*/
    font-weight: 500;
    height: 1.5rem;
    padding: 0 0.5rem;
}
.btn:hover {
    text-decoration: none;
}
.btn:visited {
    color: #0079b8;
}
.btn:hover {
    background-color: #e1f1f6;
    color: #004d8a;
}
.btn:active {
    -webkit-box-shadow: 0 2px 0 0 #0095d3 inset;
    box-shadow: 0 2px 0 0 #0095d3 inset;
}
.btn.disabled, .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: transparent;
    border-color: #737373;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.btn.btn-secondary,
.btn.btn-info,
.btn.btn-outline,
.btn.btn-primary-outline,
.btn.btn-secondary-outline,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-info-outline,
.btn-secondary .btn,
.btn-info .btn,
.btn-outline .btn,
.btn-primary-outline .btn,
.btn-secondary-outline .btn,
.btn-outline-primary .btn,
.btn-outline-secondary .btn,
.btn-info-outline .btn,
.btn-outline-info .btn {
    border-color: #0079b8;
    background-color: transparent;
    color: #0079b8;
}
.btn.btn-secondary:visited,
.btn.btn-info:visited,
.btn.btn-outline:visited,
.btn.btn-primary-outline:visited,
.btn.btn-secondary-outline:visited,
.btn.btn-outline-primary:visited,
.btn.btn-outline-secondary:visited,
.btn.btn-info-outline:visited,
.btn-secondary .btn:visited,
.btn-info .btn:visited,
.btn-outline .btn:visited,
.btn-primary-outline .btn:visited,
.btn-secondary-outline .btn:visited,
.btn-outline-primary .btn:visited,
.btn-outline-secondary .btn:visited,
.btn-info-outline .btn:visited,
.btn-outline-info .btn:visited {
    color: #0079b8;
}
.btn.btn-secondary:hover,
.btn.btn-info:hover,
.btn.btn-outline:hover,
.btn.btn-primary-outline:hover,
.btn.btn-secondary-outline:hover,
.btn.btn-outline-primary:hover,
.btn.btn-outline-secondary:hover,
.btn.btn-info-outline:hover,
.btn-secondary .btn:hover,
.btn-info .btn:hover,
.btn-outline .btn:hover,
.btn-primary-outline .btn:hover,
.btn-secondary-outline .btn:hover,
.btn-outline-primary .btn:hover,
.btn-outline-secondary .btn:hover,
.btn-info-outline .btn:hover,
.btn-outline-info .btn:hover {
    background-color: #e1f1f6;
    color: #004d8a;
}
.btn.btn-secondary:active,
.btn.btn-info:active,
.btn.btn-outline:active,
.btn.btn-primary-outline:active,
.btn.btn-secondary-outline:active,
.btn.btn-outline-primary:active,
.btn.btn-outline-secondary:active,
.btn.btn-info-outline:active,
.btn-secondary .btn:active,
.btn-info .btn:active,
.btn-outline .btn:active,
.btn-primary-outline .btn:active,
.btn-secondary-outline .btn:active,
.btn-outline-primary .btn:active,
.btn-outline-secondary .btn:active,
.btn-info-outline .btn:active,
.btn-outline-info .btn:active {
    -webkit-box-shadow: 0 2px 0 0 #0095d3 inset;
    box-shadow: 0 2px 0 0 #0095d3 inset;
}
.btn.btn-secondary.disabled, .btn.btn-secondary:disabled,
.btn.btn-info.disabled,
.btn.btn-info:disabled,
.btn.btn-outline.disabled,
.btn.btn-outline:disabled,
.btn.btn-primary-outline.disabled,
.btn.btn-primary-outline:disabled,
.btn.btn-secondary-outline.disabled,
.btn.btn-secondary-outline:disabled,
.btn.btn-outline-primary.disabled,
.btn.btn-outline-primary:disabled,
.btn.btn-outline-secondary.disabled,
.btn.btn-outline-secondary:disabled,
.btn.btn-info-outline.disabled,
.btn.btn-info-outline:disabled,
.btn-secondary .btn.disabled,
.btn-secondary .btn:disabled,
.btn-info .btn.disabled,
.btn-info .btn:disabled,
.btn-outline .btn.disabled,
.btn-outline .btn:disabled,
.btn-primary-outline .btn.disabled,
.btn-primary-outline .btn:disabled,
.btn-secondary-outline .btn.disabled,
.btn-secondary-outline .btn:disabled,
.btn-outline-primary .btn.disabled,
.btn-outline-primary .btn:disabled,
.btn-outline-secondary .btn.disabled,
.btn-outline-secondary .btn:disabled,
.btn-info-outline .btn.disabled,
.btn-info-outline .btn:disabled,
.btn-outline-info .btn.disabled,
.btn-outline-info .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: transparent;
    border-color: #737373;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-primary,
.btn-primary .btn {
    border-color: #0079b8;
    background-color: #0079b8;
    color: #fff;
}
.btn.btn-primary:visited,
.btn-primary .btn:visited {
    color: #fff;
}
.btn.btn-primary:hover,
.btn-primary .btn:hover {
    background-color: #004d8a;
    color: #e1f1f6;
}
.btn.btn-primary:active,
.btn-primary .btn:active {
    -webkit-box-shadow: 0 2px 0 0 #0095d3 inset;
    box-shadow: 0 2px 0 0 #0095d3 inset;
}
.btn.btn-primary.disabled, .btn.btn-primary:disabled,
.btn-primary .btn.disabled,
.btn-primary .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: #ccc;
    border-color: #ccc;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-success,
.btn-success .btn {
    border-color: #2f8400;
    background-color: #2f8400;
    color: #fff;
}
.btn.btn-success:visited,
.btn-success .btn:visited {
    color: #fff;
}
.btn.btn-success:hover,
.btn-success .btn:hover {
    background-color: #266900;
    color: #fff;
}
.btn.btn-success:active,
.btn-success .btn:active {
    -webkit-box-shadow: 0 2px 0 0 #1d5100 inset;
    box-shadow: 0 2px 0 0 #1d5100 inset;
}
.btn.btn-success.disabled, .btn.btn-success:disabled,
.btn-success .btn.disabled,
.btn-success .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: #ccc;
    border-color: #ccc;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-danger,
.btn.btn-warning,
.btn-danger .btn,
.btn-warning .btn {
    border-color: #e12200;
    background-color: #e12200;
    color: #fff;
}
.btn.btn-danger:visited,
.btn.btn-warning:visited,
.btn-danger .btn:visited,
.btn-warning .btn:visited {
    color: #fff;
}
.btn.btn-danger:hover,
.btn.btn-warning:hover,
.btn-danger .btn:hover,
.btn-warning .btn:hover {
    background-color: #c92100;
    color: #fff;
}
.btn.btn-danger:active,
.btn.btn-warning:active,
.btn-danger .btn:active,
.btn-warning .btn:active {
    -webkit-box-shadow: 0 2px 0 0 #a32100 inset;
    box-shadow: 0 2px 0 0 #a32100 inset;
}
.btn.btn-danger.disabled, .btn.btn-danger:disabled,
.btn.btn-warning.disabled,
.btn.btn-warning:disabled,
.btn-danger .btn.disabled,
.btn-danger .btn:disabled,
.btn-warning .btn.disabled,
.btn-warning .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: #ccc;
    border-color: #565656;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-outline,
.btn.btn-outline .btn,
.btn.btn-info-outline,
.btn-info-outline .btn,
.btn.btn-outline-info,
.btn-outline .btn {
    border-color: #0079b8;
    background-color: transparent;
    color: #0079b8;
}
.btn.btn-outline:visited,
.btn.btn-outline .btn:visited,
.btn.btn-info-outline:visited,
.btn-info-outline .btn:visited,
.btn.btn-outline-info:visited,
.btn-outline .btn:visited {
    color: #0079b8;
}
.btn.btn-outline:hover,
.btn.btn-outline .btn:hover,
.btn.btn-info-outline:hover,
.btn-info-outline .btn:hover,
.btn.btn-outline-info:hover,
.btn-outline .btn:hover {
    background-color: #e1f1f6;
    color: #004d8a;
}
.btn.btn-outline:active,
.btn.btn-outline .btn:active,
.btn.btn-info-outline:active,
.btn-info-outline .btn:active,
.btn.btn-outline-info:active,
.btn-outline .btn:active {
    -webkit-box-shadow: 0 2px 0 0 #0095d3 inset;
    box-shadow: 0 2px 0 0 #0095d3 inset;
}
.btn.btn-outline.disabled, .btn.btn-outline:disabled,
.btn.btn-outline .btn.disabled,
.btn.btn-outline .btn:disabled,
.btn.btn-info-outline.disabled,
.btn.btn-info-outline:disabled,
.btn-info-outline .btn.disabled,
.btn-info-outline .btn:disabled,
.btn.btn-outline-info.disabled,
.btn.btn-outline-info:disabled,
.btn-outline .btn.disabled,
.btn-outline .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: transparent;
    border-color: #737373;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-success-outline,
.btn.btn-outline-success,
.btn-success-outline .btn,
.btn-outline-success .btn {
    border-color: #2f8400;
    background-color: transparent;
    color: #2f8400;
}
.btn.btn-success-outline:visited,
.btn.btn-outline-success:visited,
.btn-success-outline .btn:visited,
.btn-outline-success .btn:visited {
    color: #2f8400;
}
.btn.btn-success-outline:hover,
.btn.btn-outline-success:hover,
.btn-success-outline .btn:hover,
.btn-outline-success .btn:hover {
    background-color: #dff0d0;
    color: #1d5100;
}
.btn.btn-success-outline:active,
.btn.btn-outline-success:active,
.btn-success-outline .btn:active,
.btn-outline-success .btn:active {
    -webkit-box-shadow: 0 2px 0 0 #60b515 inset;
    box-shadow: 0 2px 0 0 #60b515 inset;
}
.btn.btn-success-outline.disabled, .btn.btn-success-outline:disabled,
.btn.btn-outline-success.disabled,
.btn.btn-outline-success:disabled,
.btn-success-outline .btn.disabled,
.btn-success-outline .btn:disabled,
.btn-outline-success .btn.disabled,
.btn-outline-success .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: transparent;
    border-color: #737373;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-danger-outline,
.btn.btn-outline-danger,
.btn.btn-warning-outline,
.btn.btn-outline-warning,
.btn-danger-outline .btn,
.btn-outline-danger .btn,
.btn-warning-outline .btn,
.btn-outline-warning .btn {
    border-color: #c92100;
    background-color: transparent;
    color: #e12200;
}
.btn.btn-danger-outline:visited,
.btn.btn-outline-danger:visited,
.btn.btn-warning-outline:visited,
.btn.btn-outline-warning:visited,
.btn-danger-outline .btn:visited,
.btn-outline-danger .btn:visited,
.btn-warning-outline .btn:visited,
.btn-outline-warning .btn:visited {
    color: #e12200;
}
.btn.btn-danger-outline:hover,
.btn.btn-outline-danger:hover,
.btn.btn-warning-outline:hover,
.btn.btn-outline-warning:hover,
.btn-danger-outline .btn:hover,
.btn-outline-danger .btn:hover,
.btn-warning-outline .btn:hover,
.btn-outline-warning .btn:hover {
    background-color: #f5dbd9;
    color: #a32100;
}
.btn.btn-danger-outline:active,
.btn.btn-outline-danger:active,
.btn.btn-warning-outline:active,
.btn.btn-outline-warning:active,
.btn-danger-outline .btn:active,
.btn-outline-danger .btn:active,
.btn-warning-outline .btn:active,
.btn-outline-warning .btn:active {
    -webkit-box-shadow: 0 2px 0 0 #f8b7b6 inset;
    box-shadow: 0 2px 0 0 #f8b7b6 inset;
}
.btn.btn-danger-outline.disabled, .btn.btn-danger-outline:disabled,
.btn.btn-outline-danger.disabled,
.btn.btn-outline-danger:disabled,
.btn.btn-warning-outline.disabled,
.btn.btn-warning-outline:disabled,
.btn.btn-outline-warning.disabled,
.btn.btn-outline-warning:disabled,
.btn-danger-outline .btn.disabled,
.btn-danger-outline .btn:disabled,
.btn-outline-danger .btn.disabled,
.btn-outline-danger .btn:disabled,
.btn-warning-outline .btn.disabled,
.btn-warning-outline .btn:disabled,
.btn-outline-warning .btn.disabled,
.btn-outline-warning .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: transparent;
    border-color: #565656;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-link,
.btn-link .btn {
    border-color: transparent;
    background-color: transparent;
    color: #0079b8;
}
.btn.btn-link:visited,
.btn-link .btn:visited {
    color: #0079b8;
}
.btn.btn-link:hover,
.btn-link .btn:hover {
    background-color: transparent;
    color: #004d8a;
}
.btn.btn-link:active,
.btn-link .btn:active {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn.btn-link.disabled, .btn.btn-link:disabled,
.btn-link .btn.disabled,
.btn-link .btn:disabled {
    color: #565656;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.
.btn.btn-inverse:active, .alert-app-level .alert-item .btn:active,
.btn-inverse .btn:active {
    -webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25) inset;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25) inset;
}
.btn.btn-inverse.disabled, .alert-app-level .alert-item .btn.disabled, .btn.btn-inverse:disabled, .alert-app-level .alert-item .btn:disabled,
.btn-inverse .btn.disabled,
.btn-inverse .btn:disabled {
    color: #fff;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: transparent;
    border-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-sm, .alert-app-level .alert-item .btn,
.btn-sm .btn {
    line-height: calc(1rem - 1px);
    letter-spacing: 0.073em;
    font-size: 0.458333rem;
    font-weight: 500;
    height: 1rem;
    padding: 0 0.5rem;
}

.btn {
    margin: 0.25rem 0.5rem 0.25rem 0;
}


.table {
    border-collapse: separate;
    border: 1px solid #ccc;
    border-radius: 0.125rem;
    background-color: #fff;
    color: #565656;
    margin: 0;
    margin-top: 1rem;
    max-width: 100%;
    width: 100%;
}
.table th,
.table td {
    font-size: 0.8rem;
    line-height: 0.8rem;
    border-top: 1px solid #eee;
    padding: 0.4583333333rem 0.5rem 0.4583333333rem;
    text-align: center;
    vertical-align: top;
}
.table th.left,
.table td.left {
    text-align: left;
}
.table th.left:first-child,
.table td.left:first-child {
    padding-left: 0.25rem;
}
.table th {
    color: #565656;
    font-weight: 600;
    letter-spacing: 0.03em;
    background-color: #fafafa;
    vertical-align: bottom;
    border-bottom: 1px solid #ccc;
    border-top: 0 none;
}
.table tbody tr:first-child td {
    border-top: 0 none;
}
.table tbody + tbody {
    border-top: 1px solid #ccc;
}
.table thead th:first-child {
    border-radius: calc(0.125rem - 1px) 0 0 0;
}
.table thead th:last-child {
    border-radius: 0 calc(0.125rem - 1px) 0 0;
}
.table tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 calc(0.125rem - 1px);
}
.table tbody:last-child tr:last-child td:last-child {
    border-radius: 0 0 calc(0.125rem - 1px) 0;
}


.addressDropdown li{
    list-style-type:none;
    transition: all .2s ease-in-out;
}
.addressDropdown li:hover{
    list-style-type:none;
    cursor: pointer;
    transform-origin:left;
    transform: scale(1.1);
    color:#000000;
}
